<style scoped>
.demand-table-row{
    background-color: #141414;
    padding: 5px 10px;
    cursor: pointer;
}
.demand-table-row:hover{
    background-color: #444;
}
.demand-table-row-active{
    background-color: #2d8cf0;
    padding: 5px;
    cursor: pointer;
}
</style>

<template>
    <div>
        <Divider dashed><span class="divider-text">广告投放汇总</span></Divider>
        <!-- <Row class="p-b-5">
            <i-col span="4">日期：</i-col>
            <i-col span="20">
                <DatePicker size="small" v-model="searchStartDate" type="date" :options="formatMaxDate" :clearable="false"></DatePicker>
            </i-col>
        </Row> -->

        <Row class="p-b-5">
            <i-col span="4">检索：</i-col>
            <i-col span="20">
                 <Input size="small" v-model="adQuery.keyword" search placeholder="品牌、客户关键字搜索..." @on-search="handelSearchAd"/>
            </i-col>
        </Row>

        <div v-for="(item,index) in adList" :key="index" @click="handleClickAd(item)">
            <p :class="chooseAd === item.id?'demand-table-row-active':'demand-table-row'" >
                <span class="p-l-5" >{{item.advertiser}} ({{item.brand}})</span>
            </p>
        </div>

        <div class="paging_style">
          <Page size="small" :total="50" :page-size="10" show-total show-elevator :current="1"></Page>
        </div>
    </div>
</template>

<script>
// import { GetDateStr } from '@/utils/dateFormat'

export default {
  data () {
    return ({
      // searchStartDate: GetDateStr(Date.now(), 0),
      // formatMaxDate: {
      //   disabledDate (date) {
      //     return date.valueOf() > Date.now() + 86400000
      //   }
      // },
      adQuery: {
        keyword: ''
      },
      adList: [],
      totalAd: 0,
      chooseAd: null
    })
  },
  created () {
    this.initPageData()
  },
  methods: {
    initPageData () {
      this.adList = [
        { id: 1, advertiser: '中广青岛分公司', brand: '软件园1' },
        { id: 2, advertiser: '北京极地海洋世界', brand: '极地海昌.0929' },
        { id: 3, advertiser: '青岛123有限公司', brand: '青岛啤酒纯生、青岛其' },
        { id: 4, advertiser: '苹果公司', brand: '苹果手机' },
        { id: 5, advertiser: '乘车', brand: '汽车品牌' },
        { id: 6, advertiser: '青岛0916测试有限公司', brand: '0916测试品牌' }
      ]
    },
    handelSearchAd () {

    },
    handleClickAd (ad) {
      if (this.chooseAd === ad.id) {
        this.chooseAd = null
      } else {
        this.chooseAd = ad.id
      }
    }
  }
}
</script>
